/* eslint-disable react/no-danger */
import React from 'react';
import TextHeader, { SIZE, WEIGHT } from '@components/TextHeader';
import { Container, Wrapper } from '@components/Slug/styles';

function SlugPage({ slug }) {
  const { staticPage: data } = slug;
  if (!slug || !Object.keys(slug).length) {
    return null;
  }

  return (
    <Wrapper>
      <Container>
        <TextHeader size={SIZE.XL} weight={WEIGHT.MEDIUM}>
          {data.title}
        </TextHeader>
        <div dangerouslySetInnerHTML={{ __html: data.content }} />
      </Container>
    </Wrapper>
  );
}

export default SlugPage;
