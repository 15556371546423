import React from 'react';
import TextHeader, { SIZE, WEIGHT, TAG } from '@components/TextHeader';
import { ErrorPage } from '@styles/globalStyles';

function GlobalError({ message }) {
  return (
    <ErrorPage>
      <TextHeader tag={TAG.H1} size={SIZE.MD} weight={WEIGHT.MEDIUM}>
        {message}
      </TextHeader>
    </ErrorPage>
  );
}

export default GlobalError;
