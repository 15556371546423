import React from 'react';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { GetServerSidePropsContext } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { API, APIRoutes } from '@utils/api';

import SlugPage from '@components/Slug';
import GlobalError from '@components/GlobalError';

type StaticPageProps = {
  slug: {
    staticPage: {
      title: string;
    };
  };
  error: boolean;
};

function StaticPage({ slug, error }: StaticPageProps) {
  const { t } = useTranslation('common');

  return (
    <>
      <Head>
        <title>{error ? t('Page Not Found', { ns: 'common' }) : slug.staticPage.title}</title>
      </Head>
      {error ? <GlobalError message={t('Page Not Found', { ns: 'common' })} /> : <SlugPage slug={slug} />}
    </>
  );
}

export async function getServerSideProps({ locale, params }: GetServerSidePropsContext) {
  const getTranslations = serverSideTranslations(locale, [
    'homepage',
    'navbar',
    'footer',
    'newsletter',
    'autocomplete',
    'common',
  ]);
  try {
    const { data: slug } = await API.get(APIRoutes.staticPage(params.slug));
    return {
      props: {
        ...(await getTranslations),
        slug,
        error: false,
      },
    };
  } catch (error) {
    return {
      props: {
        ...(await getTranslations),
        slug: {},
        error: true,
      },
    };
  }
}

export default StaticPage;
