import styled from '@emotion/styled';
import { fontSize, ContentWrapper } from '@styles/globalStyles';
import { breakpoint } from '@styles/breakpoints';

export const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderSeparator.default};
`;

export const Container = styled(ContentWrapper)`
  padding: 72px 0;

  @media only screen and (max-width: 1350px) {
    padding: 25px 15px;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 20px 0;
  }

  h1 {
    @media only screen and ${breakpoint.md} {
      font-size: ${fontSize(42)};
    }
  }

  h2 {
    font-size: ${fontSize(42)};

    @media only screen and ${breakpoint.md} {
      font-size: ${fontSize(30)};
    }
  }

  h3 {
    font-size: ${fontSize(36)};

    @media only screen and ${breakpoint.md} {
      font-size: ${fontSize(28)};
    }
  }

  h4 {
    font-size: ${fontSize(28)};

    @media only screen and ${breakpoint.md} {
      font-size: ${fontSize(24)};
    }
  }

  h5 {
    font-size: ${fontSize(22)};
  }

  h6 {
    font-size: ${fontSize(18)};
  }

  p,
  ul,
  ol,
  a {
    font-family: ${({ theme }) => theme.defaults.fontFamily.secondary};
    font-size: ${fontSize(14)};
    font-weight: 500;
    line-height: 1.71;
    letter-spacing: -0.21px;
    color: #00405b;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    margin: 20px 0;
  }

  ul,
  ol {
    list-style: initial;
    margin: 20px 0 20px 30px;

    li {
      margin: 5px 0;
    }
  }

  a {
    background-color: inherit;
    font-size: ${fontSize(14)};
    font-weight: 500;
    letter-spacing: -0.21px;
    font-family: ${({ theme }) => theme.defaults.fontFamily.primary};
    color: ${({ theme }) => theme.colors.link.primary};

    &:hover {
      color: ${({ theme }) => theme.colors.link.onHover};
    }

    &:active {
      color: ${({ theme }) => theme.colors.link.click};
    }
  }

  & strong {
    font-weight: bold !important;
  }

  & em {
    font-style: italic !important;
  }

  tbody {
    border: ${({ theme }) => `2px solid ${theme.colors.borders.primary}`};

    td {
      padding: 10px;
      border: ${({ theme }) => `2px solid ${theme.colors.borders.primary}`};
    }
  }
`;
